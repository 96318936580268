
// .popUpOuterDiv {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   z-index: 1020;
//   top: 0;
//   left: 0;
//   height: 110vh;
//   width: 100vw;
//   background-color: #000000c7;
//   touch-action: none;
// }


// .popUp {
//   padding: 22px 15px;
//   border-radius: 20px;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   background: var(--mainColorLight);
// }

// .popUpClose {
//   z-index: 1000;
//   position: absolute;
//   width: 18px;
//   right: 15px;
//   top: 30px;
//   stroke: "gery";
// }
.popUpOuterDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  // background-color: #000000c7;
  touch-action: none;
  // display: none;
}

.popUp {
  padding: 22px 15px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: var(--mainColorLight);
  width: 100%;
  height: 100%;
  .popUpClose {
    z-index: 1000;
    position: absolute;
    width: 18px;
    right: 15px;
    top: 30px;
    stroke: var(--greyWhite);
  }
}

.popUpheading {
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  color: var(--blackWhite);
}

.popUpText {
  color: var(--blackWhite);
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.centerButton {
  width: 50%;
  margin: 0 auto;
  min-width: 180px;
}

.popUpFullButton {
  width: 100%;
}

.popUpLink {
  color: var(--mainColor);
  font-weight: 500;
  margin: 0;
  margin-top: 10px;
}
.homeButton {
  padding: 10px 0;
  border-radius: 30px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  // justify-content: space-evenly;
  justify-content: center;
  position: relative;
  cursor: pointer;
  max-height: 46px;
  &:hover {
    font-weight: 600;
    transform: scale(1.03);
    box-shadow: 0 0 5px 5px var(--mainColor15);
  }
  svg {
    margin-right: 12px;
  }
}
.buttonFilled {
  border: 1px solid var(--transparent);
  color: var(--whiteText);
  background: var(--mainColor);

  &.buttonFilledPreview {
    background: var(--previewMainColor);
    color: var(--previewFont);
    svg {
      width: 20px;
      fill: var(--previewFont);
      stroke: var(--previewFont);
    }
  }

  cursor: pointer;

  svg {
    width: 20px;
    fill: var(--whiteText);
    stroke: var(--whiteText);
  }
}
.buttonUnFilled {
  border: 1px solid var(--mainColorWhite);
  color: var(--mainColorWhite);
  background: var(--transparent);
  &.buttonUnFilledPreview {
    border: 1px solid var(--previewMainColor);
    color: var(--previewMainColor);
    svg {
      width: 20px;
      fill: var(--previewMainColor);
    }
  }

  svg {
    width: 20px;
    fill: var(--mainColorWhite);
  }
}

.buttonWhite {
  border: 1px solid var(--transparent);
  color: var(--mainColor);
  background: var(--white);
  cursor: pointer;

  svg {
    width: 20px;
    fill: var(--mainColor);
  }
  &.buttonWhitePreview {
    color: var(--previewMainColor);
    background: var(--white);

    // color: var(--previewFont);

    svg {
      fill: var(--previewFont);
    }
  }
}
.twoButtonDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  .homeButton {
    width: 47%;
  }
  button {
    width: 47%;
  }
  .deviceTag {
    width: 26%;
    padding: 5px;
    margin-right: 15px;
    &.buttonUnFilled {
      border-color: var(--grey);
      color: var(--grey);
    }
    input:checked + .homeButton {
      background: #000;
      border-color: green;
      color: white;
    }
  }
}
.cropButton {
  top: 16px !important;
}