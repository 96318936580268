// .qrPopUpLinkIcon {
//   display: flex;
//   cursor: pointer;
// }

// .qrPopUpLinkIconDiv {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 50px;
// }

// .qrPopUpLink {
//   max-width: calc(100% - 60px);
//   overflow: hidden;
//   white-space: nowrap;
//   margin: 0;
// }

// .qrPopUpLinkDiv {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   background: "lime";
//   border-radius: 65px;
//   padding: 15px 17px;
// }


// .colorBox {
//   width: 25px;
//   height: 25px;
//   background: "green";
//   border-radius: 360px;
//   position: relative;
//   margin-right: 10px;
//   aspect-ratio: 1;
//   border: 1px solid "grey";
//   margin-bottom: 8px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }


// .selectedColorBox {
//   box-shadow: 1px 1px 5px red;
//   transform: scale(1.1);

// }

// .colorDiv {
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
// }


// .qrDiv {
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }


// .qrDownload {
//   width: 25px;
//   fill: "grey";
//   position: absolute;
//   top: 30px;
//   z-index: 1000;
//   left: 15px;
// }


// .qrPopUp .qrPopUpImage canvas {

//   width: 50%;
//   margin: 30px auto;
//   display: block;

// }
.qrPopUp {
  .qrPopUpImage {
    canvas {
      width: 50%;
      margin: 30px auto;
      display: block;
    }
  }
}
.qrPopUpLinkDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--whiteGrey);
  border-radius: 65px;
  padding: 15px 17px;
}
.qrPopUpLink {
  max-width: calc(100% - 60px);
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}
.qrPopUpLinkIconDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
}
.qrPopUpLinkIcon {
  display: flex;
  cursor: pointer;
}
.qrlogo {
  width: 75px;
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  margin: 0 auto;
  // border-radius: 150px;
}
.qrDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content: center;

  //   justify-content: space-between;
}

.selectedColorBox {
  // border: 2px solid var(--white);
  box-shadow: 1px 1px 5px var(--mainColor);
  transform: scale(1.1);
  svg {
    stroke: var(--white);
    width: 80%;
  }
}

.colorBox {
  width: 25px;
  height: 25px;
  background: var(--previewMainColor);
  border-radius: 360px;
  position: relative;
  margin-right: 10px;
  aspect-ratio: 1;
  border: 1px solid var(--grey);
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrDownload{
  width: 25px;
  fill: var(--greyWhite);
  position: absolute;
  top: 30px;
  z-index: 1000;
  left: 15px;
}