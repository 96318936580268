// svg {
//   stroke: var(--white);
//   width: 80%;
// }



html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

:root {
  --gradientBackground: linear-gradient(
    180deg,
    rgb(235, 255, 250, 0.5),
    rgb(133, 191, 180, 0.5)
  );
  --gradientSideNav: linear-gradient(
    90deg,
    rgb(58, 132, 118, 0.2),
    rgb(58, 132, 118, 0)
  );
  --mainColorLight: rgb(232, 241, 239);
  --mainColor: rgb(58, 132, 118);
  --mainColor10: rgb(58, 132, 118, 10%);
  --mainColor15: rgb(58, 132, 118, 15%);
  --leftSide: #ffffff;
  --whiteText: #ffffff;
  --white: #ffffff;
  --whiteBlack: #ffffff;
  --black: #000000;
  --grey: #9b9b9b;
  --transparent: transparent;
  --noneInformation: #cecece;
  --mainColorWhite: rgb(58, 132, 118);
  --mainColorGrey: rgb(58, 132, 118);
  --whiteLightGrey: #ffffff;
  --whiteGrey: #ffffff;
  --blackWhite: #000000;
  --placeholder: #909090;
  --greyWhite: #707070;
  --previewLeftSide: #ffffff;
  --previewFont: #ffffff;
  --previewMainColor: rgb(58, 132, 118);
  --previewGradientBackground: linear-gradient(
    180deg,
    rgb(235, 255, 250, 0.5),
    rgb(133, 191, 180, 0.5)
  );
}

[data-theme="dark"] {
  --gradientBackground: #000000;
  --gradientSideNav: linear-gradient(
    90deg,
    rgb(58, 132, 118, 0.2),
    rgb(58, 132, 118, 0)
  );
  --mainColorLight: #3a3a3a;
  --mainColor: rgb(58, 132, 118);
  --mainColor10: #000000;
  --mainColor15: rgb(58, 132, 118, 15%);
  --leftSide: #ffffff;
  --whiteText: #ffffff;
  --white: #ffffff;
  --whiteBlack: #000000;
  --black: #000000;
  --grey: #9b9b9b;
  --transparent: transparent;
  --noneInformation: #cecece;
  --mainColorWhite: #ffffff;
  --mainColorGrey: #b0b0b0;
  --whiteLightGrey: #c4c4c4;
  --whiteGrey: #404040;
  --blackWhite: #ffffff;
  --placeholder: #b2b2b2;
  --greyWhite: #ffffff;
  --previewLeftSide: #ffffff;
  --previewFont: #ffffff;
  --previewMainColor: rgb(58, 132, 118);
  --previewMainColorWhite: #ffffff;
  --previewGradientBackground: linear-gradient(
    180deg,
    rgb(235, 255, 250, 0.5),
    rgb(133, 191, 180, 0.5)
  );
}
body {
  margin: 0;
  padding: 0;
  background: var(--gradientBackground);
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

.mainDiv {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  /* align-items: center; */
  box-sizing: border-box;
  overflow: hidden;
}

.mainSubDivSpacing {
  padding: 0 15px;
  width: 100%;
  position: relative;
}

.mainSubDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  max-width: 425px;
  width: 100%;
  position: relative;
}